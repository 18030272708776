/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import RenderSections from '@ihs/Common/RenderSections';
import WrapLayout from '@ihs/components/Layout/WrapLayout';
import HeadComponent from '@ihs/components/Utility/HeadComponent';

/* Services */
import { performRequest } from '@utils/microsites/DatoCMSHelperService';
import { getMenuQuery, getQuery } from '@utils/microsites/IHSGqlQueries';

/* Styles */

/**
 *
 * @param {object} pageData
 * @param lang
 * @param region
 * @returns
 */

const IhsHome = ({ pageData, region, lang }) => {
  // console.log('IhsHome', pageData.body);

  if (pageData?._seoMetaTags) {
    pageData._seoMetaTags.push({
      tag: 'meta',
      content: null,
      attributes: {
        name: 'description',
        content: pageData.summary || pageData.title,
      },
    });
  }

  return (
    <WrapLayout pageData={pageData}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData?.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Box as="section" className="container-article" p="0" bgColor="#fff">
        {pageData?.body?.map(section => {
          return (
            <RenderSections
              pageData={pageData}
              section={section}
              region={region}
              lang={lang}
              key={nanoid(4)}
            />
          );
        })}
      </Box>
    </WrapLayout>
  );
};

export const getStaticProps = async () => {
  const headerMenuData = await performRequest(
    getMenuQuery('Ihsmainmenu'),
    'Ihsmainmenu'
  );

  const response = await performRequest(getQuery('isha-home-school'), 'isha-home-school');
  let pageData = response.allIhsLandingPages[0] || [];
  pageData = JSON.stringify(pageData).replace(
    /https:\/\/www.datocms-assets.com\//g,
    `${config.staticPath}/d/`
  );
  pageData = JSON.parse(pageData);

  const footerData = await performRequest(
    getMenuQuery('Ihsfooter'),
    'Ihsfooter'
  );

  return {
    props: {
      headerMenuData: headerMenuData || null,
      pageData: pageData || '',
      footerData: footerData || null,
      menuType: 'sw',
    },
  };
};

export default IhsHome;
